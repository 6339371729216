import { openMessage } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import {
    getCurrentStatementQualify,
    getStatementQualify,
    getStatementQualifyEvents,
    putStatementRequestQualify,
    qualifyStatementRequestQualify,
    qualifyStatementSlice,
} from '.'

function* qualifyStatementRequestHandler({
    payload,
}: ReturnType<typeof qualifyStatementRequestQualify>) {
    try {
        const { error, data } = yield* call(api.lib.requestQualifyRegistryService, payload.data)

        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.item) {
            openMessage({
                type: 'success',
                message: 'Заявка сохранена',
            })

            setTimeout(() => {
                payload.navigate(`../qualification-statement/${data.item.application_uuid}`)
            }, 1000)
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка  при запросе для установки квалификации',
        })
        yield* put(qualifyStatementSlice.actions.setError(String(error)))
    } finally {
        yield* put(qualifyStatementSlice.actions.setIsLoading(false))
    }
}

function* getCurrentStatementQualifyHandler({
    payload,
}: ReturnType<typeof getCurrentStatementQualify>) {
    try {
        yield* put(qualifyStatementSlice.actions.clearCurrentDataAndEvents())
        const { error, data } = yield* call(api.lib.getQualifyRegistryService, payload)

        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.item && data?.item.items) {
            const items = data?.item.items.sort((a, b) => b.created_at - a.created_at)
            yield* put(qualifyStatementSlice.actions.setCurrentData(items[0]))
        }
    } catch (error) {
        // TODO: ошибка приходит если заявки нет, хотелось бы чтобы это было не ошибкой, отключил
        /*openMessage({
            type: 'error',
            message: 'Возникла ошибка ',
        })*/

        yield* put(qualifyStatementSlice.actions.setError(String(error)))
    } finally {
        yield* put(qualifyStatementSlice.actions.setIsLoading(false))
    }
}

function* getStatementQualifyHandler({ payload }: ReturnType<typeof getStatementQualify>) {
    try {
        const { error, data } = yield* call(api.lib.getQualifyRegistryService, payload)

        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.items) {
            yield* put(qualifyStatementSlice.actions.setData(data?.items))
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка ',
        })
        yield* put(qualifyStatementSlice.actions.setError(String(error)))
    } finally {
        yield* put(qualifyStatementSlice.actions.setIsLoading(false))
    }
}

function* getStatementQualifyEventsHandler({
    payload,
}: ReturnType<typeof getStatementQualifyEvents>) {
    try {
        const { error, data } = yield* call(api.lib.getQualifyRegistryEventsService, payload)

        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.items) {
            yield* put(qualifyStatementSlice.actions.clearDataAndEvents(data?.items))
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка ',
        })
        yield* put(qualifyStatementSlice.actions.setError(String(error)))
    } finally {
        yield* put(qualifyStatementSlice.actions.setIsLoading(false))
    }
}

function* qualifyStatementPutHandler({ payload }: ReturnType<typeof putStatementRequestQualify>) {
    try {
        const { error, data } = yield* call(api.lib.updateQualifyRegistryService, payload)

        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.item) {
            openMessage({
                type: 'success',
                message: 'Заявка отправлена на согласование',
            })
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникла ошибка  при запросе для установки квалификации',
        })
        yield* put(qualifyStatementSlice.actions.setError(String(error)))
    } finally {
        yield* put(qualifyStatementSlice.actions.setIsLoading(true))
    }
}

export function* qualifyStatementWatch(): Generator<StrictEffect> {
    yield* takeLatest(qualifyStatementRequestQualify.type, qualifyStatementRequestHandler)
    yield* takeLatest(getCurrentStatementQualify.type, getCurrentStatementQualifyHandler)
    yield* takeLatest(getStatementQualify.type, getStatementQualifyHandler)
    yield* takeLatest(getStatementQualifyEvents.type, getStatementQualifyEventsHandler)
    yield* takeLatest(putStatementRequestQualify.type, qualifyStatementPutHandler)
}

import { createSelector } from '@reduxjs/toolkit'

import {
    IBusinessmanProfile,
    ILegalEntityProfile,
    IssueAvailableInvestorsType,
    ProfileStatus,
    ProfileType,
    UserRoles,
    blockedRoles,
} from '@dltru/dfa-models'
import { getEmitterData, getPathByProfileType } from '@dltru/dfa-ui'

import { getUserFullNameByType, getUserInnByType } from '@utils/profile'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import IAppState from '../../states'

const selectProfile = (state: IAppState) =>
    state.profileDetails.userInfo?.profile_data?.[
        getPathByProfileType[state.profileDetails.userInfo.profile_data.type]
    ]

const selectProfileAgent = (state: IAppState) =>
    state.profileDetails.userInfo?.profile_data?.agents?.[0]

const selectProfileTypes = (state: IAppState) =>
    state.profileDetails.userInfo?.profile_data?.type
        ? [state.profileDetails.userInfo?.profile_data?.type]
        : null

const emitterData = createSelector(selectProfileTypes, selectProfile, (types, profile) =>
    types && profile
        ? getEmitterData(types, profile as ILegalEntityProfile | IBusinessmanProfile)
        : null,
)

const userFullName = createSelector(selectProfileTypes, selectProfile, (types, profile) =>
    types && profile ? getUserFullNameByType(types[0], profile) : null,
)

const userInn = createSelector(selectProfileTypes, selectProfile, (types, profile) =>
    types && profile ? getUserInnByType(types[0], profile) : null,
)

const agentFullName = createSelector(selectProfileAgent, (profile) =>
    profile
        ? `${profile.person.last_name} ${profile.person.first_name} ${profile.person.second_name}`
        : null,
)

// TODO исправить на корректную проверку (когда будут приходить соответсвующие данные)
const isEmitter = createSelector(
    selectProfileTypes,
    (types) => types?.includes(ProfileType.BUSN) || types?.includes(ProfileType.LEGL),
)

const selectAuthData = (state: IAppState) => state.profileDetails.userInfo?.authorize_data

const isPartlyBlocked = createSelector(
  selectAuthData,
  (authData) => authData.role === UserRoles.CLIENT_PARTLY_BLOCK
)

const selectApprovalHistory = (state: IAppState) => state.profileDetails.approvalHistory ?? []

const registrationOfApplicationDate = createSelector(
    selectApprovalHistory,
    (statuses) =>
        statuses.find((status) => status.profile_status === ProfileStatus.OPERATOR_APPROVAL)
            ?.approval_datetime,
)

const applicationDate = createSelector(
    selectApprovalHistory,
    (statuses) =>
        statuses.find((status) => status.profile_status === ProfileStatus.APPROVED)
            ?.approval_datetime,
)

const selectQualifyInfo = (state: IAppState) => state.profileDetails.qualifyInfo

const isPersonProfile = createSelector(selectProfileTypes, (types) =>
    types?.includes(ProfileType.PRSN),
)

const isCheckQuota = createSelector(
    selectQualifyInfo,
    isPersonProfile,
    (qualify) => qualify?.is_qualified !== true,
)

const selectLimitRublies = createSelector(selectQualifyInfo, isPersonProfile, (qualify) =>
    qualify?.is_qualified === false ? qualify.quota / 100 : undefined,
)

const selectBeneficiariesUuids = createSelector(selectProfile, (profile) => {
    if (!profile) {
        return null
    }
    if ('beneficial_owners_uuids' in profile) {
        return profile.beneficial_owners_uuids
    }
    if ('beneficiaries' in profile) {
        return profile.beneficiaries
    }
})

const selectBankAccountDetails = createSelector(selectProfile, (profile) => {
    if (profile) {
        return profile.user_bank_account_details?.bank_account_details
    }
    return null
})

const selectOtherClient = (state: IAppState) => state.profileDetails.otherClientProfile
const selectWalletDfaData = (state: IAppState) => state.profileDetails.userInfo?.wallet_dfa_data

const selectOtherClientProfileType = (state: IAppState) =>
    state.profileDetails.otherClientProfileTypes?.[0]

const selectIsUserBlocked = createSelector(selectAuthData, (authData) => {
    if (authData) {
        return blockedRoles.includes(authData.role)
    }

    return false
})

const selectIsQualify = createSelector(
    dfaDetailsSelector.selectDfaDetails,
    (state: IAppState) => state.profileDetails.qualifyInfo?.is_qualified,
    (dfa, isQualify) =>
        dfa.issue_available_investors_type === IssueAvailableInvestorsType.qualified && isQualify,
)

const selectBlockUnqual = createSelector(
    dfaDetailsSelector.selectDfaDetails,
    (state: IAppState) => state.profileDetails.qualifyInfo?.is_qualified,
    (dfa, isQualify) =>
        dfa.issue_available_investors_type === IssueAvailableInvestorsType.qualified && !isQualify,
)

const selectIsUnqualifyHideLimit = createSelector(
    dfaDetailsSelector.selectDfaDetails,
    isCheckQuota,
    (dfa, isQuota) =>
        dfa.issue_available_investors_type ===
            IssueAvailableInvestorsType.qualified_and_unqualified_without_limit && isQuota,
)

const selectIsUnqualifyLimit = createSelector(
    dfaDetailsSelector.selectDfaDetails,
    isCheckQuota,
    (dfa, isQuota) =>
        dfa.issue_available_investors_type_limits ===
            IssueAvailableInvestorsType.qualified_and_unqualified_with_limit && isQuota,
)

export const profileSelector = {
    selectProfile,
    selectProfileAgent,
    selectProfileTypes,
    emitterData,
    userFullName,
    isEmitter,
    selectAuthData,
    selectApprovalHistory,
    registrationOfApplicationDate,
    applicationDate,
    selectQualifyInfo,
    selectIsQualify,
    selectBeneficiariesUuids,
    isCheckQuota,
    selectLimitRublies,
    selectBankAccountDetails,
    selectOtherClient,
    selectOtherClientProfileType,
    selectIsUserBlocked,
    selectIsUnqualifyHideLimit,
    selectIsUnqualifyLimit,
    selectBlockUnqual,
    isPersonProfile,
    agentFullName,
    selectWalletDfaData,
    userInn,
    isPartlyBlocked
}

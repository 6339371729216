import {
    IInvestorRegistryApplicationPost,
    IInvestorRegistryApplicationPut,
    InvestorRegistryApplication,
    InvestorRegistryApplicationEvent,
} from '@dltru/dfa-models'
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'

interface IQualifyStatement {
    isLoading: boolean
    error: string | null
    data: InvestorRegistryApplication[]
    currentData?: InvestorRegistryApplication
    events: InvestorRegistryApplicationEvent[]
}

const initialState: IQualifyStatement = {
    isLoading: false,
    error: null,
    data: [],
    events: [],
}

export const qualifyStatementSlice = createSlice({
    name: 'qualifyStatement',
    initialState,
    reducers: {
        setError(state, action: PayloadAction<string | null>) {
            state.error = action.payload
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setData(state, action: PayloadAction<InvestorRegistryApplication[]>) {
            state.isLoading = false
            state.error = null
            state.data = action.payload
        },
        clearDataAndEvents(state) {
            state.error = null
            state.data = []
        },
        setEvents(state, action: PayloadAction<InvestorRegistryApplicationEvent[]>) {
            state.events = action.payload
        },
        setCurrentData(state, action: PayloadAction<InvestorRegistryApplication>) {
            state.isLoading = false
            state.error = null
            state.currentData = action.payload
        },
        clearCurrentDataAndEvents(state) {
            state.error = null
            state.currentData = undefined
            state.events = []
        },
    },
})

type TIInvestorRegistryApplicationPost = {
    data: IInvestorRegistryApplicationPost
    navigate: NavigateFunction
}

export const qualifyStatementRequestQualify = createAction<TIInvestorRegistryApplicationPost>(
    'register/qualifyStatementRequestQualify',
)
export const getCurrentStatementQualify = createAction<string>(
    'register/getCurrentStatementQualify',
)
export const getStatementQualify = createAction<string>('register/getStatementQualify')
export const getStatementQualifyEvents = createAction<string>('register/getStatementQualifyEvents')
export const putStatementRequestQualify = createAction<IInvestorRegistryApplicationPut>(
    'register/putStatementRequestQualify',
)

export default qualifyStatementSlice.reducer

import IAppState from '../states'

const selectQualifyStatements = (state: IAppState) => state.qualifyStatement.data
const selectCurrentQualifyStatement = (state: IAppState) => state.qualifyStatement.currentData
const selectCurrentQualifyStatementEvents = (state: IAppState) => state.qualifyStatement.events
const selectIsLoaded = (state: IAppState) => state.qualifyStatement.isLoading

export const qualifyStatementSelector = {
    selectQualifyStatements,
    selectCurrentQualifyStatement,
    selectCurrentQualifyStatementEvents,
    selectIsLoaded,
}
